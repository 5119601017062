.app {
  @apply flex flex-col w-full h-full overflow-hidden;

  aside.ant-layout-sider.ant-layout-sider-dark.sidebar-wrapper::before {
    content: "";
    position: absolute;
    width: 260px;
    height: 100%;
    box-shadow: inset 0px 20px 15px -10px rgba(0, 0, 0, 0.3);
    pointer-events: none;
  }

  .sidebar-wrapper {
    /* TODO: try figure out a way to do this through config */
    &.ant-layout-sider {
      font-weight: 300 !important;
      min-width: 200px !important;
      flex: 0 0 220px !important;
      max-width: 200px !important;
    }

    .ant-layout-sider-children {
      .ant-menu {
        height: 100%;
      }

      .sidebar {
        @apply h-full flex flex-col;

        &--mobile {
          @apply z-50 pb-16;
        }
      }
    }
  }

  .sidebar--mobile::before {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 20px 15px -10px rgba(0, 0, 0, 0.3);
    pointer-events: none;
  }

  .mobile-nav {
    @apply absolute flex left-0 h-full w-full overflow-hidden;
  }

  .main-content {
    @apply bg-triple-background-new w-full flex items-start justify-center overflow-y-auto;

    .content-wrapper {
      @apply w-full h-full self-start py-4 pl-1 md:pr-14 overflow-x-hidden relative;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
