.alert-unsaved-changes {
  .ant-modal-content {
    @apply bg-triple-header;
  }

  .ant-modal-confirm-content,
  .ant-modal-confirm-title {
    color: #f6f6f6 !important;
  }

  .btn-cancel {
    color: #f6f6f6 !important;
  }

  .btn-leave {
    background: #ff4d4f !important;
    box-shadow: 0 2px 0 rgba(255, 38, 5, 0.06) !important;
    color: #fff !important;
    border-color: #ff4d4f !important;
  }

  .btn-leave:hover {
    background: #ff7875 !important;
    border-color: #ff7875 !important;
  }
}

.custom-create-user-modal-content {
  .ant-modal-content {
    @apply border border-solid border-triple-blue;
  }
}
